import React from 'react'
import {
  useViewportScroll,
  motion,
  useTransform,
} from 'framer-motion';
//import { useInView } from 'react-intersection-observer';
import styled  from '@emotion/styled';
import Img from 'gatsby-image';
import { theme } from '../../config/theme';

const StyledHeroImage = styled.div`
overflow:hidden;
position:relative;
margin-top: 40px;
min-height: 420px;
@media (max-width: ${theme.breakpoints.m}) {
  min-height: 260px;

    }
.img-fluid {
    position:absolute !important;
    height: 100%;

    z-index: 0;
  }
`
const HeroBG = styled(motion.div)`
  //background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size:cover;
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
 
`
const HeroContent = styled.div`
  padding: 9em 0;
  @media (max-width: ${theme.breakpoints.l}) {
    padding: 4em 0;

    }
  display:flex;
  align-items:center;
  justify-content:center;
  z-index: 1;
  position:relative;
  h1{
    font-size: 44px;
    font-weight:900;
    color:#fff;
    font-style:italic;
    text-shadow: 1px 1px 1px black;
  }
`
 const HeroImage = ({ title,image,...props }) => {
  const { scrollY } = useViewportScroll();
  const { scrollYProgress } = useViewportScroll()
  const y1 = useTransform(scrollY, [0, 500], [50, 0]);

  /*const [, inView, entry] = useInView({
    threshold: 0.5,
    triggerOnce: false
  });*/

  return (<StyledHeroImage {...props}>
   {/*<HeroBG style={{y:y1,backgroundImage:"url(/assets/images/hero-team.png)"}} />*/}
   {image && image.fluid &&<Img className="img-fluid" alt={`Bild: ${title}`} fluid={image.fluid} /> }
    <HeroContent>
      <h1>{title}</h1>
    </HeroContent>
  </StyledHeroImage>)
 }

 export default HeroImage;