import React, {useState} from 'react'
import styled from "@emotion/styled"
import Logo from '../logo/logo'
import Navigation from '../navigation/navigation'
import Container from './container'
import Headroom from 'react-headroom'

const StyledHeader = styled.header`
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(248,248,248,1) 100%);
    //margin-bottom: 8em;
    z-index: 3;
    //position:fixed;
    width: 100%;
    padding-top: 28px;

    
    > div {
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      
        //padding: 6em inherit;
        
    }
`
const XtraHeaderTop = styled.div`
  position:fixed;
    width:100vw;
    height: ${props => props.isMenuOpen ? '100vh' : '28px'};
    transition: height .6s cubic-bezier(0.075, 0.82, 0.165, 1);
z-index:2;
    overflow:hidden;
    &:before{
        content:'';
        position:absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: -1;
        width: 100%;
        background: #222;
        background-image: url('/assets/images/schiefer.jpg');
        background-size: cover;
        background-repeat:no-repeat;
        background-position: center center;
    }
`

const Header = ({location}) => {
    const [isMenuOpen,setIsMenuOpen] = useState(false);
    return (<>
    
    <Headroom disableInlineStyles>
    <XtraHeaderTop isMenuOpen={isMenuOpen} />
    <StyledHeader>
        <Container>
            <Logo />
            <Navigation onChange={(value)=>setIsMenuOpen(value)} location={location}/>
        </Container>
    </StyledHeader>
    </Headroom></>

)}
export default Header;
