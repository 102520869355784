import React,{useState,useRef,useEffect} from 'react'
import { Link } from 'gatsby'
//import styles from './navigation.module.css'
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { theme } from '../../config/theme';
import Burger from './burger';
import logo from '../icons/logo-white.svg'

const menuItems = [
  {
    title: `Aktuelles &
  Projekte`, to: '/'
  },
  {
    title: `Kunden &
   Leistungen`, to: '/kunden-leistungen'
  },
  {
    title: `Team &
  Partner`, to: '/team-und-partner'
  }]

const Menu = styled(motion.ul)`
  list-style:none;
  display: flex;
  flex-wrap: wrap;
  flex-direction:row;
  z-index: 9;
  margin: 0;
  padding: 0;
  li.meta{
    display:none;
  }
  @media (min-width: ${theme.breakpoints.l}) {
    opacity: 1 !important;
  }
  @media (max-width: ${theme.breakpoints.l}) {
    position:fixed;
    display: ${props => props.open ?'flex' :'none'};
    z-index: ${props => props.open ?'3' :'-1'};
    opacity: ${props => props.open ?'1' :'0'};
    left: 0;
    width: 100vw;
    height: 100vh;
    //background: white;
    top: 0;
    padding-top: 120px;
    flex-direction: column;
    align-items: center;
    li {
      margin-bottom: 40px;
      &.meta {
        margin-bottom: 0;
        margin-top: 20px;
        display:block;
      }

  a {
    color:white !important;
    margin: 0;
    display:block;
    text-align:center;
    opacity: 0.9;
  }
      }
    }
  }
`
const StyledMenuItem = styled(motion.li)`
  font-weight: 400;
  font-size: 1.35em;
 
  line-height: 1.1em;
 
  position:relative;
  @media (min-width: ${theme.breakpoints.l}) {
    max-width: 170px;
    margin-left: 2em;
    min-width:90px;
    width: 150px;
    font-size: 1.15em;
    white-space: pre-line;
    opacity: 1 !important;
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    width: 170px;
    text-align:left;
  }
  @media (max-width: ${theme.breakpoints.l}) { 
      &:after {
        display:none;
      }
      a {
      color:white;
    }
  }

  &:after{
    z-index:2;
    content:'';
    transition: .38s all cubic-bezier(0.075, 0.82, 0.165, 1);

    position:absolute;
    bottom: -34px;
    left: 0;
    width: 20px;
    height: 2px;
    background-color: #ddd;
   
  }
  &:hover:after {
      width: 40px;
    }
  ${props=>props.isSelected && `
    color: ${theme.colors.primary};
        &:after{
          width: 100% !important;
          background-color: ${theme.colors.primary};
        }
  `}
 
`
const WhiteLogo = styled(motion.div)`
@media (min-width: ${props => theme.breakpoints.l}) { 
    display:none;
  }
  top: -50px;
    position: relative;
`

const  MenuToggle = styled(Burger)`
display:none;
cursor:pointer;
@media (max-width: ${props => theme.breakpoints.l}) { 
      display: block;
}
  z-index: 9999;
  span {
    background:${props => props.isOpen ?'white' :'black'} !important;
    }
`;

const  RotateContainer = styled.div`
  height: 100%;
  width: 100%;
  transition: ${(props) => props.open ? 'all 0.15s ease-in-out' : 'all 0.15s ease-in-out'};
  transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'none')};
`;

export default ({location,onChange}) => {
    const [selected, setSelected] = useState(location.pathname);
    const [isOpen, setOpen] = useState(false)
  const toggleNav = () => setOpen(!isOpen)

  const nodeRef = useRef()
  

  useEffect(() => {
    onChange(isOpen);
    const handleOutsideClick = e => {
      if (!nodeRef.current.contains(e.target)) {
        toggleNav()
      }
    }
    /*if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick)
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick)
    }*/
  }, [isOpen])
  useEffect(() => {
    setOpen(false);
   
  }, [location.pathname])

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.01
      }
    }
  }
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }
  return (<>
        <MenuToggle  
        isOpen={isOpen}
        menuClicked={()=>toggleNav()}
        width={28}
        height={20}
        strokeWidth={1}
        rotate={0}
        color='black'
        borderRadius={0}
        animationDuration={0.5}
        >
</MenuToggle>     
    <nav role="navigation">
      
      
      <Menu 
      initial='hidden'
      animate={isOpen ? 'show' : 'hidden'}
      variants={container} ref={nodeRef} open={isOpen}>
        <WhiteLogo variants={item}> <img src={logo} alt="Logo" /></WhiteLogo>
        {
          menuItems.map(menuItem => <MenuItem variants={item} onClick={()=>setOpen(false)} key={menuItem.to}
            isSelected={location.pathname.replace(/\//g,'') === menuItem.to.replace(/\//g,'')}
             {...menuItem} />)
        }
       <motion.li variants={item} className="meta">
              <Link  onClick={()=>setOpen(false)} to="/kontakt">Kontakt</Link></motion.li>
              <motion.li variants={item} className="meta">   <Link onClick={()=>setOpen(false)} to="/impressum">Impressum</Link></motion.li>
              <motion.li variants={item} className="meta">   <Link onClick={()=>setOpen(false)} to="/datenschutz">Datenschutz</Link></motion.li>
      </Menu>
    </nav></>
  )
}

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30
};

const MenuItem = ({ title, to,isSelected,...props }) => (
  <StyledMenuItem  {...props} isSelected={isSelected}>
    <Link to={to}>{title}</Link>
  </StyledMenuItem>
)