import React from "react"
import styled from "@emotion/styled"
import { theme } from '../../config/theme';
import {RelatedLinks} from "../elements/relatedLinks";
import Button from '../elements/button';

const StyledClientDetail = styled.div`
    /*padding: ${theme.gap.normal};
    max-width: 600px;
    margin: 0 auto;*/
`

const ClientDetail = ({ data: client,onClose,origData }) => {
    console.log('CLIENT DETAIL',client,origData)
    return (<StyledClientDetail>
        <div className="modal_innerContent">
        <div className="client-logo-holder"><img className={client.logo.file.details.image.width > client.logo.file.details.image.height ? 'client-logo img-landscape' : 'client-logo img-portrait'} width={client.logo.file.details.image.width} height={client.logo.file.details.image.height} src={client.logo.file.url} alt={client.name} /></div>
        <h1>
            {client.name}
        </h1>
        {
            client.body && 
        
        <div
            className="paragraph"
            dangerouslySetInnerHTML={{
                __html: client.body.childMarkdownRemark.html,
            }}
        />}
        {
            client.artikel &&
                <RelatedLinks title={'Projekte'} data={client} modalType={"post"} />
        }</div>
        {
            onClose && <Button className="modal-close" name="Schließen" onClick={onClose}>Schließen</Button>
          }
    </StyledClientDetail>);
}

export default ClientDetail;