import React from 'react'
import styled from '@emotion/styled';
import { theme} from '../../config/theme';

const StyledContainer = styled.div`
max-width: ${theme.maxWidth};
padding: 0 ${theme.space[8]}px;
margin: 0 auto;

@media (max-width: ${props => theme.breakpoints.l}) {
      padding: 0 30px;
    }
@media (max-width: ${props => theme.breakpoints.m}) {
      padding: 0 20px;
    }
//background-color:yellow;
`

export default ({ children }) => (
  <StyledContainer>{children}
  <svg width="0" height="0" style={{position:'absolute'}}><filter id="blur3px"><feGaussianBlur in="SourceGraphic" stdDeviation="3"></feGaussianBlur></filter></svg></StyledContainer>
)
