
import React from "react";
import Layout from './src/components/layout/layout';
import { ModalProvider } from './src/components/modal/modalContext';


const transitionDelay = 500;

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};
export const wrapRootElement = ({ element }) => (
  <ModalProvider>{element}</ModalProvider>
)

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if(!location.hash){
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }}
  return false;
};

/*export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    //import('intersection-observer');
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}*/