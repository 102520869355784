import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useForm } from "react-hook-form";
import Container from '../layout/container';
import Button from '../elements/button';
import { theme } from '../../config/theme';
const StyledContactForm = styled.div`
  background:linear-gradient(#fff, #D8D8D8);
  padding-bottom: 130px;
 
  .contact-form__header {
    text-align:center;
    h2,h3{
      color:#000;
      font-size: 2.5em;
    margin-bottom: -2px;
    }
    p{
      font-size: 1.3em;
      a{
        text-decoration:underline;
      }
      
    }
  }
  .datenschutz {
        line-height: 1.4em;
        margin: -10px 0 40px;
      }
`

const Form = styled.form`
 max-width: 1200px;
 margin: 0 auto;
  list-style-type: none;
 
  label.hide{
    display:none;
  }
  input, textarea {
    -webkit-appearance: none;
    border-radius: 0;
    font-size: 1.15em;
  }
  input:not([type=submit]),select,option,textarea {
    border:none;
    //border 1px solid ${props => theme.colors.greyLight};
    width: 100%;
    padding: 12px 20px;
    @media (max-width: ${theme.breakpoints.l}) {
      padding: 8px 16px;
    }
    overflow:hidden;
    color: ${props => theme.colors.grey};
    &:focus {
      outline: 0;
      //border-color: ${props => theme.colors.grey};
    }
  }
  textarea{
    height: 200px;
    width: 100%;
  }
  textarea ~ label {
    display:block !important;
  }


  .contact-form__disclaimer {
    max-width: 1090px;
    margin: 1em auto;
  }

  label {
    //border: 1px solid ${props => theme.colors.greyLight};
    border-bottom-width: 0;
    width: 100%;
    margin-bottom: 0;
    padding: 6px 11px;
    z-index: -1;
    position:absolute;
    top: 20px;
  }
  label.withCheckbox {
    border-bottom-width: 1px;
    margin: 15px 0;
    span {
      display:inline-block;
      width: 15px;
      height: 15px;
      top: 2px;
      position:relative;
      margin-right: 10px;
      border: 1px solid ${props => theme.colors.greyLight};
    }
    &.active {
      span {
        background-color: ${props => theme.colors.red};
        border-color: ${props => theme.colors.red};
      }
    }
  }
  select {
    border-radius: 0;
    -webkit-appearance: none;
    width: 50%;
    @media (max-width: ${props => theme.breakpoints.s}) {
      width: 100%;
    }
    background-color: #fff;
    position:relative;

    &+ span {
      transform: rotate(180deg);
      display:block;
      position:absolute;
      left: 225px;
      top: 0px;
    }
  }
  input[type=checkbox] {
    display:inline-block;
  }
  input[type=checkbox]+label{
    display:block;
    background: #fff;
    display:flex;
    pointer-events: none;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => theme.colors.greyLight};
  }
  input[type=checkbox]:checked + label {
    color:#fff;
    background-color: ${props => theme.colors.red};
    border-color: ${props => theme.colors.red};
  }
`

const CheckBox = styled.div`
position:relative;
width: 50px;height:50px;
display: inline-block;
cursor:pointer;
margin-right: 10px;
> label, >input {
  position:absolute;
  top: 0;
  left: 0;
  width: 50px;height:50px;
}
`
const FormField = styled.div`
  display:block;
  position:relative;
`
const FormRow = styled.div`
  display:flex;
  //margin: -15px;
  flex-direction:row;
  @media (max-width: ${props => theme.breakpoints.s}) {
    flex-direction:column;

  width: 100%;
  }
  > div {
    width: 100%;

    overflow:hidden;
    margin: 15px;
    @media (max-width: ${props => theme.breakpoints.s}) {
      width: 100%;
      margin: 15px 0;
    }
  }
`

const Error = styled.div`
color:${props => theme.colors.red};
`

const DayChooser = styled.div`
border: 1px solid ${props => theme.colors.greyLight};
border-top-width: 0;
margin-top: -15px;
margin-bottom: 15px;
display:flex;
justify-content: center;
align-items:center;

@media (max-width: ${props => theme.breakpoints.s}) {
  align-items:top;
}
padding: 0.5em 15px;
.label {
  margin-right: 1em;
}
`

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}
const ContactForm = ({hideTitle}) => {
  const [showDays, setShowDays] = useState(false);
  const [sentSuccess, setSentSuccess] = useState(false);
  const [sentFailed, setSentFailed] = useState(false);
  const { register, errors, handleSubmit } = useForm();
  const onSubmit = data => {

    const formData = JSON.stringify(data)
    //const formData = new FormData(objectToFormData(data))
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data })
    }).then(() =>  setSentSuccess(true))
    .catch(error => setSentFailed(true))

  };

  return (
  <StyledContactForm>
    <Container>
     
  {
  (sentSuccess || sentFailed) ?
    <>
      {
        sentSuccess && <div className="contact-form__header">
        <h2 className="no-border">Danke fürs Hallo sagen!</h2></div>
      }
      {
        sentFailed && <div className="contact-form__header">
        <h2 className="no-border">Es ist leider ein Fehler aufgetreten.</h2>
        <p>Kontaktieren Sie uns per <a href="mailto:info@dg-komm.com">E-Mail</a>, per Telefon +49 (0)221 99 88 31 0 oder über das Formular.</p></div>
      }
    </>
  :
  <>
   <div className="contact-form__header">
 <h2 className="no-border">Hallo sagen!</h2>
  <p>Kontaktieren Sie uns per <a href="mailto:info@dg-komm.com">E-Mail</a>, per Telefon +49 (0)221 99 88 31 0 oder über das Formular.</p></div>
  <Form 
  name="contact" 
  method="post"
  action="/thanks"
  data-netlify="true"
  data-netlify-honeypot="bot-field"
  onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="form-name" value="contact" />
   

    <FormRow>
      <FormField>
        <label htmlFor="Vorname">Vorname</label>
        <input
          type="text"
          name="Vorname"
          id="Vorname"
          placeholder="Vorname*"
          ref={register({ required: true, maxLength: 80 })}
        />
        {errors.Vorname && <Error>Bitte geben Sie Ihren Vornamen ein</Error>}
      </FormField>
      <FormField>
        <label htmlFor="Nachname">Nachname</label>
        <input
          type="text"
          name="Nachname"
          id="Nachname"
          placeholder="Nachname*"
          ref={register({ required: true, maxLength: 100 })}
        />
        {errors.Nachname && <Error>Bitte geben Sie Ihren Nachnamen ein</Error>}
      </FormField>
    </FormRow>
    <FormRow>
      <FormField>
        <label htmlFor="Email">Email</label>
        <input
          type="text"
          name="Email"
          id="Email"
          placeholder="E-Mail-Adresse*"
          ref={register({
            required: true,
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          })}
        />
        {errors.Email && <Error>Bitte geben Sie eine gültige E-Mail-Addresse an</Error>}</FormField>
      <FormField>
        <label htmlFor="Telefonnummer">Telefonnummer</label>
        <input
          type="tel"
          name="Telefonnummer"
          id="Telefonnummer"
          placeholder="Telefon / Mobilnummer"
          ref={register({ required: false, maxLength: 18, minLength: 8 })}
        /> {errors.Telefonnummer && <Error>Bitte geben Sie eine gültige Telefonnummer an</Error>}
      </FormField></FormRow>

{/*
    <FormField>
      <label className={`withCheckbox ${showDays && 'active'}`} onClick={() => setShowDays(!showDays)}><span></span>Wollen Sie einen Termin vereinbaren?</label>
      {showDays && <DayChooser>
        <div className="label">
          Ich habe an folgenden Werktagen Zeit:
          </div>
        <div>
          <CheckBox>
            <input
              name="Tag"
              type="checkbox"
              value="Montag"
              ref={register()}
            /><label htmlFor="Tag">MO</label></CheckBox>
          <CheckBox><input
            name="Tag"
            type="checkbox"
            value="Dienstag"
            ref={register()}
          /><label htmlFor="Tag">DI</label></CheckBox>
          <CheckBox><input
            name="Tag"
            type="checkbox"
            value="Mittwoch"
            ref={register()}
          /><label htmlFor="Tag">MI</label></CheckBox>
          <CheckBox><input
            name="Tag"
            type="checkbox"
            value="Donnerstag"
            ref={register()}
          /><label htmlFor="Tag">DO</label></CheckBox>
          <CheckBox><input
            name="Tag"
            type="checkbox"
            value="Freitag"
            ref={register()}
          /><label htmlFor="Tag">FR</label></CheckBox></div></DayChooser>
      }
    </FormField>
    */}
 <FormRow>
      <FormField>
        <label htmlFor="Nachricht">Ihre Nachricht an uns*</label>
        <textarea
          type="text"
          name="Nachricht"
          id="Nachricht"
          placeholder="Ihre Nachricht an uns*"
          ref={register({
            required: true,
            //pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          })}
        />
        {errors.Nachricht && <Error>Bitte geben Sie eine Nachricht ein.</Error>}
      </FormField>
    </FormRow>
   <div className="contact-form__disclaimer" style={{textAlign:'center'}}>
     <p className="datenschutz">
     Ja, ich habe die Datenschutzerklärung zur Kenntnis genommen und bin damit einverstanden, dass die von mir angegebenen Daten elektronisch erhoben und gespeichert werden. Meine Daten werden dabei nur streng zweckgebunden zur Bearbeitung und Beantwortung meiner Anfrage genutzt. Mit dem Absenden des Kontaktformulars erkläre ich mich mit der Verarbeitung einverstanden.
     </p>
   </div>
    <div style={{ textAlign: 'center' }}>
      <Button name="submit" type="submit">abschicken</Button></div>
  </Form></>
  }</Container></StyledContactForm>);
}

export default ContactForm;