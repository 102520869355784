import React from 'react'
import styled from "@emotion/styled";
import { theme } from '../../config/theme';

const StyledButton = styled.button`
background-color: ${theme.colors.primaryLight};
color: white;
          outline:none;
          border: 1px solid ${theme.colors.primaryLight};;
          padding: 18px 0;
          width: 280px;
          font-style:italic;
          -webkit-appearance: none;
          font-size: 1.6em;
    letter-spacing: .04em;
    font-weight: 900;

          cursor: pointer;
          &:hover{
            color:${theme.colors.primaryLight};;
            background-color: #fff;
          }
          &:hover,&:active{
            outline: none;
          }

i{
  margin-right:8px;
  position: relative;
  top: 2px;
}
  ${props => props.invert && `

    border: 1px solid #fff;
    color: #fff;
    &:hover{
            color:#000;
            background-color: #fff;
          }
    `}

`;

const Button = ({children,icon:Icon,...props}) => (
    <StyledButton {...props}>
      {Icon && Icon }
      {children}
    </StyledButton>
  )
  
  export default Button