import React from 'react'
import styled from "@emotion/styled"
import Container from './container'
import { Box } from './flex/box';
import { Flex } from './flex/flex';
import { Link } from 'gatsby';
import { theme } from '../../config/theme';

const StyledFooter = styled.footer`
    color: #fff;
    padding: 57px 0 20px;
    position:relative;
    width:100vw;
    overflow:hidden;
    &:before{
        content:'';
        position:absolute;
        left: 0;
        top: 0;
        height: 100%;
        z-index: -1;
        width: 140%;
        //background: rgb(192,245,254);
        //background: linear-gradient(231deg, rgba(192,245,254,1) 0%, rgba(2,162,217,1) 74%, rgba(2,162,217,1) 100%);
        background: #222;
        background-image: url('/assets/images/schiefer.jpg');
        background-size: cover;
        background-repeat:no-repeat;
        background-position: center center;
    }
    > div {
        
    }
    .say-hi {
        width: 70px;
        margin-left: -60px;

        @media (max-width: ${theme.breakpoints.l}) {
            position:absolute;
            right: 20px;
            top: 40px;
        }
    }
    a{
        text-decoration: underline;
        opacity:.8;
        &:hover{
            opacity:1;
        }
    }
    h3{
        font-style:italic;
    }
    .footer__meta-nav {
        @media (max-width: ${theme.breakpoints.m}) {
            margin-top: 40px;
            text-align:left;
        }
        > a {
            margin-right: 20px;
        }
    }
    .footer__follow {
        font-size: 1.5em;
        line-height: 1.3em;
        a {
            font-weight: 900;
            font-style:italic;
            color:#CAF5FF !important;
        }

    }
    .footer__bottom {
        margin-top: 3em;
        font-size: 0.9em;
        margin-bottom: 0;
        @media (max-width: ${theme.breakpoints.l}) {
            margin-bottom: 2em;
        }
    }
    address {
        font-weight: 300;
        font-style: normal;
        strong{
            font-weight:800;
        }
    }
`

const boxWidth=[1,1/3];

const Footer = () => (
    <StyledFooter>
        <Container>
            <Flex>
                <Box className="footer__follow" fontSize={32} width={boxWidth}>
                    Folge uns auf<br />
                    <a href="https://www.linkedin.com/company/derichs-graalmann-kommunikation-gmbh" rel="noreferrer" target="_blank">
                        LinkedIn
                    </a> &amp;<br/>
                    <a href="https://twitter.com/DGkomm" rel="noreferrer" target="_blank">
                        Twitter
                    </a> &amp;<br/>
                    <a href="https://www.facebook.com/derichsgraalmann" rel="noreferrer" target="_blank">
                        Facebook
                    </a>
                </Box>
                <Box width={boxWidth}>
                    <address><h3>Kontakt</h3>
                    <strong>Derichs &amp; Graalmann</strong><br />
                    Kommunikation GmbH<br />
                    <br />
                    Platenstraße 44<br />
                    50825 Köln</address>
                </Box>
                <Box width={boxWidth}>
                    <img className="say-hi img-fluid" src="/assets/images/say-hello.svg" alt="Say Hello" />
                    Tel.: <a href="tel:+49 (0)221 – 99 88 31-0">+49 (0)221 – 99 88 31-0</a><br />
                    <a href="mailto:info@dg-komm.com">info@dg-komm.com</a>
                </Box>
            </Flex>
            <Flex className="footer__bottom">
                <Box width={[1,1/2]}>
                    Copyright: {new Date().getFullYear()} Derichs &amp; Graalmann Kommunikation GmbH
                </Box>
                <Box className="footer__meta-nav" width={[1,1/2]} textAlign={'right'}>
                    <Link to="/kontakt">Kontakt</Link>
                    <Link to="/impressum">Impressum</Link>
                    <Link to="/datenschutz">Datenschutz</Link>
                </Box>
            </Flex>
        </Container>
    </StyledFooter>
)
export default Footer;
