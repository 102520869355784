import { useShowModal } from "../modal/modalContext";
import React from "react"

const RelatedLink = ({modalType,originData,data}) => {
  const handleShowModal = useShowModal({type:modalType,originData:originData,data:data})
  const onOpen = (e) => {
    e.preventDefault();
    handleShowModal();
  }

  return ( <a href={data.slug ? '/projekt/'+data.slug : '/impressum'} onClick={onOpen}><strong>
    {data.title || data.name}
  </strong></a> );
}


const RelatedLinks = ({title,data,from,modalType}) => {
  console.log('RELATED LINKS',title,data)
    return ( <div className="info">
      <span>{title}</span>
      <span>
        {
          from && from==='post'?
          <RelatedLink data={data} modalType={modalType} />
          :
          data.artikel &&
          data.artikel.map((it,index)=><RelatedLink key={data.id+''+it.id+''+index} originData={data} data={it} modalType={modalType} />)
        }
      </span>
    </div> );
}
 
export {RelatedLink,RelatedLinks};