import React, { useState, createContext,useEffect, useContext, useCallback } from "react"
import Modal from "./modal"

const ShowModalContext = createContext(undefined)
const DismissModalContext = createContext(undefined)

export const ModalProvider = ({ onChange,children }) => {
  const [show, setShow] = useState(false)
  //const [delayedShow, setDelayedShow] = useState(false)
  const [type, setType] = useState(undefined)
  const [props, setProps] = useState(undefined)

  const showModal = useCallback((modalType, modalProps) => {
    setShow(true)
    setType(modalType)
    setProps(modalProps)
    onChange && onChange(true);
  }, [])

  const dismissModal = useCallback(() => {
    setShow(false)
    setType(undefined)
    setProps(undefined)
    onChange && onChange(false);
  }, [])

  /*useEffect(() => {
    setTimeout(()=>setDelayedShow(show),100);
  }, [show]);

  console.log("Modal provider props", props)*/

  return (
    <div className={`wrapRootElement ${show ? 'showModal' :''}`}>
      <ShowModalContext.Provider value={showModal}>
        <DismissModalContext.Provider value={dismissModal}>
          <Modal isVisible={show} type={type} {...props} />
          {children}
        </DismissModalContext.Provider>
      </ShowModalContext.Provider>
    </div>
  )
}

/*export const useModal = () => {
  const context = useContext(ShowModalContext)

  if (context === undefined) {
    throw new Error("useShowModal must be used in a ShowModalContext")
  }
  console.log('useModal',context)
  return 'Hi'
  //return () => context(type, props)
}*/

export const useShowModal = (type, props) => {
  const context = useContext(ShowModalContext)

  if (context === undefined) {
    throw new Error("useShowModal must be used in a ShowModalContext")
  }

  return () => context(type, props)
}

export const useDismissModal = () => {
  const context = useContext(DismissModalContext)

  if (context === undefined) {
    throw new Error("useDismissModal must be used in a DismissModalContext")
  }

  return context
}
