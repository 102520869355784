import React from "react"
import HeroImage from '../hero/heroImage';
import styled from "@emotion/styled"
import { theme } from '../../config/theme';
import MailIcon from '../icons/mail.svg'
import LinkedInIcon from '../icons/linkedin.svg'
import Button from '../elements/button';

const StyledTeamDetail = styled.div`
   /* padding: ${theme.gap.normal};

max-width: 690px;
margin: 0 auto;*/
`
const CardContact = styled.div`
display:flex;
align-items:center;
justify-content:center;
top: 30px;
position:relative;
img {
  height: 19px;
  margin: 0 5px;
}
`

const TeamDetail = ({ data: person,onClose }) => {
    return (<StyledTeamDetail>
        <div class="modal_innerContent">
           {person.imageDetail && 
        <HeroImage image={person.imageDetail} title="" />}
        <h1>
            {person.name}
        </h1>
        {
            person.shortBio && 
        
        <div
            className="paragraph"
            dangerouslySetInnerHTML={{
                __html: person.shortBio.childMarkdownRemark.html,
            }}
        />}
        <CardContact>
            {person.linkedIn && <a href={person.linkedIn} rel="noreferrer" target="_blank"><img src={LinkedInIcon} alt="Kontakt: LinkedIn" /></a>}
            {person.email && <a href={`mailto:${person.email}`}><img src={MailIcon} alt="Kontakt: Mail" /></a>}
        </CardContact></div>
        {
            onClose && <Button className="modal-close" name="Schließen" onClick={onClose}>Schließen</Button>

          }
    </StyledTeamDetail>);
}

export default TeamDetail;