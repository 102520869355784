import React from 'react'
import styled from "@emotion/styled"
import { Link } from 'gatsby'
import logo from '../icons/dg-logo.svg'
import { theme } from '../../config/theme';

const Logo = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    img {
        width: auto;
        height: 60px;
        @media (max-width: ${theme.breakpoints.l}) {
          height: 50px;
        }
        display:block;
    }
`

export default () => (
  <Link to="/">
  <Logo>
       <img src={logo} alt="Logo" />
  </Logo></Link>
)
