import React from "react";
import { motion } from "framer-motion";
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image'
import { theme } from '../../config/theme';
import { css } from '@emotion/react'

const StyledImageHolder = styled(motion.div)`
cursor: ${props => (props.open || props.isTwitter) ? 'default' : 'pointer'};
position: ${props => props.open ? 'relative' : 'absolute'};
top: ${props => props.open ? '45px' : '45px'};
display: flex;
    align-items: center;
width: calc(100% - 40px);
height: ${props => props.open ? '385' : '220'}px;
margin-bottom: ${props => props.open ? '30' : '0'}px;

@media (max-width: ${theme.breakpoints.m}) {
  max-height: 220px;
    width: 100%;
    margin-bottom: 0;
    //position: ${props => props.open ? '56%' : '0'};
    padding-bottom: ${props => props.open ? '70%' : '0'};
    }
h2{display:none;}
picture{
  display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    img,source {
      position:relative !important;
      object-fit: none;
    }
}
> div.gatsby-image-wrapper {
  @media (max-width: ${theme.breakpoints.m}) {
    position: ${props => props.open ? 'absolute !important' : 'relative'};
  }
  top: 0;
  height:100%;
  width:100%;
  text-align:center;
  @media (min-width: ${theme.breakpoints.l}) {
    width: ${props => props.open ?80:100}%;
    left: ${props => props.open ?10:0}%;

    }
    > img {
      display:none;
    }
 
}

img{
  //max-width: 100%;
  max-height: 100%;
    margin: 0 auto;
    width: auto !important;
}

`
const CardImage = ({data,isOpen,...props}) => {
    return ( <StyledImageHolder layout open={isOpen} {...props}>
      
      {
        data.image.media ?
          <img src={data.image.media[0].media_url_https} alt="Twitter Image" />
        :
       
        <Img alt={`Bild: ${data.title}`} fluid={data.image.fluid} />
    
      }
    </StyledImageHolder> );
}
 
export default CardImage;        
 {/*<BackgroundImage
      Tag="div"
      className={"bg-image"}
      fluid={data.image.fluid}
      backgroundColor={`transparent`}
    >
      <h2>{`Bild: ${data.title}`}</h2>
        </BackgroundImage>*/}


{/*<img alt={`Bild: ${data.title}`} src={data.image.media[0].media_url_https} />*/} 
{ /*         <Img alt={`Bild: ${data.title}`} fluid={data.image.fluid} /> 
*/}

{/* 
<div className={`image--twitter ${!data.image.media[0] && 'image--twitter-icon'}`} style={{
backgroundPosition:'center',
backgroundImage:`url("${data.image.media[0] ? data.image.media[0].media_url_https : '/assets/images/twitter.svg'}"`}}>
</div>
*/}