const breakpoints = ["768px", "608px", "1024px", "1280px"];



//breakpoints.xs = breakpoints[0];
breakpoints.s = breakpoints[0];
breakpoints.m = breakpoints[1];
breakpoints.l = breakpoints[2];
breakpoints.xl = breakpoints[3];

const colors = {
  primary: "#0281C8",
  primaryLight: "#00A0D6",
  brand: "#00A0D6",
  lightBlue: "#CAF5FF",
  link: "#00A0D6",
  text: "#545469",
  footerBg: "#131323",
  tileBg: "#F3F3FA",
  tileIcon: "#E6E6F1",
  borders: {
    default: "rgba(255, 255, 255, 0.8)"
  }
};

export const theme = {
  modalIsOpen:false,
  mediaQueries: {
    small: `@media screen and (min-width: ${breakpoints[0]})`,
    medium: `@media screen and (min-width: ${breakpoints[1]})`,
    large: `@media screen and (min-width: ${breakpoints[2]})`,
  },
  maxWidth: '1280px',
  breakpoints,
  colors,
  space: [
    0,
    8,
    16,
    24,
    32,
    40,
    48,
    56,
    64,
    72,
    80,
    96,
    120,
    140,
    180,
    200,
    300
  ],
  gap: {
    small: '10px',
    normal: '20px',
    big: '40px',
  },
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 56, 64, 72, 96, 120],
  fontWeights: [200, 400, 700],
  lineHeights: [
    "20px",
    "24px",
    "28px",
    "32px",
    "40px",
    "60px",
    "72px",
    "80px",
    "100px",
    "120px"
  ],
  fonts: {
    default: "'Nunito Sans', Helvetica, sans-serif"
  },
  borders: [0, `1px solid ${colors.subText}`],
  radii: ["4px", "8px", "16px", "32px", "50%"]
};

export default {
  breakpoints
};
