import styled from "@emotion/styled"
import { Box } from './box';

import { compose, flexbox } from 'styled-system';

const composedHelpers = compose(flexbox);

export const Flex = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  ${composedHelpers}
  
`;

Flex.defaultProps = {};
