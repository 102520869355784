import React, { useState } from "react";
import styled from '@emotion/styled';
import { theme } from '../../config/theme';
import { useShowModal } from "../modal/modalContext";
import { RelatedLinks } from '../elements/relatedLinks';
import CardImage from './cardImage';
import ClampLines from 'react-clamp-lines';


const StyledCardContent = styled.div`
z-index:2;
    width: 100%;
    text-align: ${props => props.isOpen ? 'left;' : 'center'};
    margin-top: ${props => props.isOpen ? '40px' : '290px'};
    max-width: ${props => props.isOpen ? '800px' : 'calc(100% - 40px)'};
.fb500-links {
  margin-top: 2em;
  text-align:center;
}
h4,.card-content-subtitle {
  font-weight: 900;
  font-size: 1.5em;
  line-height: 1.3em;
  margin: 0;
  font-style:italic;
}
&.is-over h4 {
  ${props => !props.isOpen && !props.isTwitter && `color: ${theme.colors.primary};`}
    
  }

 

.card-content-subtitle{
  font-weight: 300;
}

.card-content-meta{
  margin: 30px 0 50px;
}


.card-content-category {
  position:absolute;
  top: 20px;
  right: 40px;
  font-weight: 600;
  color: ${theme.colors.primary};
  &.is-open {
    left:40px;
    @media (max-width: ${theme.breakpoints.l}) {
      left:20px;
    }
    right:auto;
    top: 30px;
  }
  //${props => props.isOpen ? 'left: 0;' : ''}
}
.card-content-footer {
  position:absolute;
  display:flex;
  justify-content:space-between;
  bottom: 20px;
  width: calc(100% - 40px);
}
`

const CardContent = ({ data, originData, onOpen, id, isOpen }) => {
  const [isOver, setIsOver] = useState(false);

  const isTwitter = data.category === 'Twitter';
  const kundenData = originData != undefined ? originData : data.kunde || null;
  const handleShowModal = useShowModal({ type: "post", data: data })
  const onClick = (e) => {

    e.preventDefault();
    handleShowModal();

  }
let categories = data?.category?.length>0 ?data.category.map(c=>c.title).join(', ') :'Corporate Publishing';
//if(data.category.length>1)categories=categories.substr(0,categories.length-2)


  return (<>
    {data.image && <CardImage isTwitter={isTwitter} onMouseEnter={() => setIsOver(true)} onMouseLeave={() => setIsOver(false)} onClick={(e) => { !isTwitter && onClick(e) }} data={data} isOpen={isOpen} />}
    <StyledCardContent  isTwitter={isTwitter} className={`modal_innerContent ${isOver && 'is-over'} ${data.fb500links && 'card--fb500'}`} isOpen={isOpen}>
      <div className={`card-content-category ${isOpen && 'is-open'}`}>{isTwitter ? 'Twitter' : data.fb500links ? 'Digital Content' : 'Projekt'}</div>
      {/* 1>0 && data.date && <div className="card-content-date">{data.date}</div> */}
      <div >
        {isOpen || isTwitter ? 
          <h4>{isTwitter ? 'Derichs & Graalmann twittern' : data.title}</h4> : <a href={`/projekt/${data.slug}`} onClick={onClick}><h4 className="link">{data.title}</h4></a>} </div>
      {
        !isOpen && data.description &&
        <ClampLines className="card-content-subtitle"
          innerElement="p"
          lines={3}
          buttons={false}
          id={`teaser-${data.id}`}
          text={data.description.childMarkdownRemark.excerpt || data.description.childMarkdownRemark.html} />

      }
      {
        isOpen && data.body &&
        <div
          className="paragraph"
          dangerouslySetInnerHTML={{
            __html: data.body.childMarkdownRemark.html,
          }}
        />
      }
      {
        isOpen && data.fb500links &&
        <div>
          <div className="paragraph fb500-links">
            {data.fb500links.map(fb500link => <div className="fb500-link">
              <a rel="noopener" target="_blank" href={fb500link.url}>{fb500link.title}</a> <span>{new URL(fb500link.url).hostname} - {fb500link.datum}</span>
            </div>)}
          </div>
          <h4 className="abo">
            Abonnieren Sie unseren FB500 Newsletter!
      </h4>
        </div>
      }

      {
        isOpen &&
        <div className="card-content-meta">
          {
            kundenData &&
            <RelatedLinks title={'Kunde'} from="post" data={kundenData} modalType={"client"} />

          }
          {(data.leistung || categories) && <div className="info"><span>Leistung</span><strong>{categories}{(data.leistung && data.leistung!==categories) ? `, ${data.leistung}` :''}</strong></div>}
          {data.produkt && !data.fb500links && <div className="info"><span>Produkt</span><strong>{data.produkt}</strong></div>}

        </div>}
      <div className="card-content-footer">
        <div>
          {
            isTwitter &&
            <a rel="noreferrer" href="https://twitter.com/dgkomm" target="_blank">Follow us</a>
          }
        </div>
        {
          !isOpen &&
          <div>
            {
              data.entities && data.entities.urls ?
                <a rel="noopener" className="read-more" href={`https://twitter.com/DGkomm/status/${data.id_str}`} target="_blank">Zum Tweet</a>
                :
                <a className="read-more" href={`/projekt/${data.slug}`} onClick={onClick}>Weiterlesen</a>
            }
          </div>}
      </div>


    </StyledCardContent></>);
}

export default CardContent;
