import React, { useRef, useEffect } from "react"
import styled from '@emotion/styled';
import { useDismissModal } from "./modalContext"
import TeamDetail from "../team/teamDetail";
import { useLockBodyScroll } from "../../utils/hooks";
import CardDetail from '../card/cardDetail';
import { AnimatePresence, motion } from 'framer-motion';
import Button from '../elements/button';
import CloseButton from '../card/closeButton';
import ClientDetail from '../clients/clientDetail';
import { theme } from '../../config/theme';

const scrollToRef = (ref) => ref.current.scrollTop = 0;


/*export const ModalType = {
  default: DefaultModal
}*/
const ModalComponent = ({ data, originData, type = "team" }) => {
  useLockBodyScroll(true);
  const dismiss = useDismissModal();
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)
  useEffect(() => {
    console.log('ALERT ')
    executeScroll()
  }, [data])

  return <ModalBackground onClick={dismiss}>

    <ModalContent
      ref={myRef}
      initial={{ opacity: 0, scale: 0.3 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      onClick={(e) => e.stopPropagation()} >
      <CloseButton bright={type === 'post'} bright={data && data.highlight} onClose={dismiss} />

      <ModalInner>
        {type === 'team' && <TeamDetail onClose={dismiss} data={data} />}
        {type === 'post' && <CardDetail onClose={dismiss} originData={originData} data={data} />}
        {type === 'client' && <ClientDetail onClose={dismiss} data={data} />}
       </ModalInner>
    </ModalContent>
  </ModalBackground>
};

const Modal = ({ isVisible, type, ...props }) => {
  return <AnimatePresence>
    {
      isVisible && <ModalComponent type={type.type} originData={type.originData} data={type.data} />
    }
  </AnimatePresence>
}

export default Modal

export const ModalBackground = styled(motion.div)`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
`

export const ModalContent = styled(motion.div)`
  width: 100vw;
  max-width: 900px;
  @media (min-width: ${theme.breakpoints.m}) {
    max-height: 80vh;
  }
  
overflow:scroll;
    @media (max-width: ${theme.breakpoints.m}) {
      height: 100vh;
 max-height: 100vh;
 overflow:hidden;
    }
    .info{
    margin-bottom:40px;
  }
  //padding: 50px 50px;
  background-color: white;
  //border-radius: 10px;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  position: relative;
  //overflow:scroll;
  .modal-close {
    margin-top:40px;
    margin-bottom:40px;
    z-index: 301;
  }
  
`
export const ModalInner = styled(motion.div)`
  position:relative;
  min-height:100%;
  max-width: 100vw;
    overflow: hidden;

    h1 {
      margin: .97em 0;
      line-height: 1.3em;
    }

    .modal_innerContent {
      width: 100%;
    text-align: left;
    margin-top:  40px;
    max-width: 600px;
    //margin: 0 auto;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: ${theme.breakpoints.l}) {
      
        margin-bottom: 70px;
    }
    }
  > div {  
    &:first-child{
    
      width: 100%;
      height: 100%;
      text-align:center;
    top: 0;
    left: 0;
    overflow-y: scroll;
    overflow-x: hidden;

      @media (max-width: ${theme.breakpoints.m}) {
        padding: 0 20px 170px;
        position:absolute;
        //margin-bottom: 170px;
        width: calc(100% - 40px);
    }
    }
  
}
@media (max-width: ${theme.breakpoints.m}) {
 // min-height:100vh;
    }
  .modal-close {
    position: relative;
    @media (max-width: ${theme.breakpoints.l}) {
      margin-bottom: 90px;
    }
    //margin-top: 50px;
  }

  .client-logo-holder {
    margin-top: 50px;
  }
`
