import React from "react";
import styled from "@emotion/styled"
import { motion } from 'framer-motion';
import CardImage from "./cardImage";
import Button from '../elements/button';
import CardContent from './cardContent';
import { theme } from '../../config/theme';

const StyledCardDetail = styled(motion.div)`

    width:100%;
    @media (max-width: ${theme.breakpoints.l}) {
      width: calc(100% - 40px);

    }
    height: 100%;
    /*position:fixed;*/
    top: 0;
    left: 0;

    
    //position:absolute;

    overflow-y:scroll;
    overflow-x:hidden;
    z-index: 300;
    display:flex;
    align-items:center;
    flex-direction:column;
    color:#000;
  background: #F8F8F8;
  ${props => props.useGradient && `
    //background-image: linear-gradient(to bottom, #caf5ff, #00a0d6);
    background-color: ${theme.colors.primary};
    color:#fff;
    a:hover {
      color:#fff !important;
      text-decoration:underline;
    }
  `}
   /* display:flex;
    align-items:center;
    justify-content:center;
    position:fixed;
    > div {
        width: 700px;
    }*/
`
const CardDetail = ({id,data,originData,onClose,isModal=true}) => {
    
    return ( <StyledCardDetail useGradient={data.highlight} layoutId={`card-container-${id}`}>
         {1>2 && data.image && <CardImage data={data} open={true}/>}
         <CardContent isOpen={true} originData={originData} data={data} id={id} layoutId={`card-content-${id}`} />
        {isModal && <div className="modal-close text-center">
          {
            
            (onClose && data && data.fb500links) || (originData && originData.fb500links) ?
              <Button name="Zum-Newsletter" onClick={() => window.open('https://fussball500.net')}>Zum Newsletter</Button>
              : <Button name="Schließen" onClick={onClose}>Schließen</Button>


          }
        </div>}
         {/*<motion.div><Button style={{marginTop:40,marginBottom:80}} name="Schließen" onClick={onClose}>Schließen</Button></motion.div>*/}
    </StyledCardDetail> );
}
export default CardDetail;