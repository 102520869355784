import React from 'react'
import '../../styles/base.css'
import Container from './container'
import Header from './header'
//import { ThemeProvider } from 'emotion-theming'
import { theme } from '../../config/theme';
import Footer from './footer';
import { Global, css } from '@emotion/react'
import { motion, AnimatePresence } from 'framer-motion'
import ContactForm from '../contact/contactForm';
import CookieConsent from 'react-cookie-consent';

const GlobalStyle = css`
body {
  //font-size:15px;
}
  h1,h2 {
          //color: ${theme.colors.primary};
        }
        h2{
          font-size: 21px;
          white-space:pre-line;
  font-weight: 900;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.14;
  letter-spacing: 0.28px;
  position:relative;
        &:after {
          content:'';
          display: block;
          position:absolute;
          width: 27px;
          height: 2px;
          bottom: -20px;
          background-color: #D8D8D8;
        }
        &.no-border:after {display:none;}
    }
    p,.paragraph,.text,.show-all {
      font-size: 18px;
      @media (max-width: ${theme.breakpoints.l}) {
        font-size: 16px;
        }
    }
    a.show-all {
      margin-top: 20px;
      font-style:italic;
      display:inline-block;
    }
    .link:hover,a:hover{
      color: ${theme.colors.primary};
    }
    .link:active,a:active{
      color: ${theme.colors.primaryLight};
    }
    .unstyled-list {
      list-style:none;
      li {
        list-style:none;
      }
    }
    .info {
      > span {
        display:inline-block;
        margin-right: 10px;
      }
    }
    .headroom {
      top: 0;
      left: 0;
      right: 0;
      z-index: 99 !important;
      }




      .headroom--unfixed {
      position: relative;
      transform: translateY(0);
      }
      .headroom--scrolled {
      transition: transform 200ms ease-in-out;
      }
      .headroom--unpinned {
      position: fixed;
      transform: translateY(-100%);
      }
      .headroom--pinned {
      position: fixed;
      transform: translateY(0%);
      }
      .container--narrow {
        max-width: 800px;
        margin: 0 auto;
        > div {
          background: none;
        }
      }
     
      .text-center {
        text-align:center;
      }
      .client-logo {
        max-width: 200px;
        height: auto;
       
      }
      .client-logo-holder {
text-align:center;
      }
      .link {
        cursor:pointer;
      }

      .info {display:flex;
        .link,a {
        display:block;
      }}
      .showModal main{
        filter: url(#blur3px);
      }
      .read-more {
  font-weight:900;
  font-style:italic;
}
.detail {
  padding-top: 50px;
}
.back {
  font-weight:900;
  font-style:italic;
  margin-top:25px;
  display:block;
        color: ${theme.colors.primary};
      }
.wrapRootElement  {
  width: 100vw;
  overflow:hidden;
}
.clamp-lines {
  max-height: 104px;
    overflow: hidden;
}
.bg-image {
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.link--icon {
  font-size:0;
}
`

const duration = 0.5

const variants = {
  initial: {
    opacity: 0,
    scale: 1.01
  },
  enter: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    scale: 0.99,
    transition: { duration: duration },
  },
}

class Layout extends React.Component {
  render() {
    const { children,location } = this.props
    
    this.componentDidMount = () => {
      if(location.hash && location.hash.length>0){
        console.log("Layout SCROLL")
        setTimeout(()=>
        window.requestAnimationFrame(() => {
          const anchor = document.querySelector(location.hash)
          console.log("Layout SCROLL",anchor)

          const offset = anchor.getBoundingClientRect().top + window.scrollY
          console.log("Layout SCROLL",offset)

          window.scroll({ top: offset, left: 0 })
      }),1200);

       
      }

    }

    return (<>
      <Global styles={GlobalStyle}/>
      <Header location={location} />
        {/* <div style={{position:'relative',top:80}}> */}
      
        <AnimatePresence>
          <motion.main
            key={location.pathname}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          > <Container>
            {children}</Container>
          </motion.main>
        
      </AnimatePresence>
      {/* </div> */}
      
      <ContactForm />
      <Footer />
      <CookieConsent
        enableDeclineButton
          location="bottom"
          buttonText="Ja, ich stimme zu"
          declineButtonText="Nein, ich lehne ab"
          buttonStyle={{ backgroundColor: "#00A0D6",color:'#fff',border:'1px solid #00A0D6' }}
          declineButtonStyle={{ background: "none", border:'1px solid #00A0D6' }}
          cookieName="gatsby-gdpr-google-analytics">
Unsere Webseite verwendet Cookies, um den bestmöglichen Service zu gewährleisten. Mit der Nutzung der Seite stimmen Sie der Cookie-Nutzung zu.
</CookieConsent>
    </>
    )
  }
}

export default Layout
