import React from "react";
import { motion } from "framer-motion";
import styled from '@emotion/styled';
import { theme } from '../../config/theme';

const StyledCloseButton = styled.div`
height:40px;
width: 40px;
position:absolute;
//right:calc(100vw - 85vw + 50px);
//top:calc(100vh - 900px + 50px);
top: 30px;
right: 40px;
@media (max-width: ${theme.breakpoints.m}) {
    top: 21px;
right: 20px;
}
color:#fff;
z-index: 301;
font-size:30px;
cursor:pointer;
color: ${props=>props.bright ? 'white' : 'black'};

display: flex;
    align-items: center;
    justify-content: center;
`
const CloseButton = ({onClose,bright=false}) => {
    return ( <StyledCloseButton bright={bright} animate onClick={onClose}>
        x
    </StyledCloseButton> );
}
 
export default CloseButton;